import React from 'react'
import styled from 'styled-components';
import Tile from '../components/Tile';
import Layout from '../layouts/';
import { CustomAnchor } from '../components/CustomLink';
import jsBindPdf from './bind-with-js.pdf';

export const TopicHeader = styled.h3`
  margin-top: 1.5rem;
  text-transform: uppercase;
  font-family: "Compacta Bold", Courier;
  -webkit-text-stroke: 1px black;
  letter-spacing: .1rem;

  font-size: 3rem;
  color: white;
  margin-bottom: .25rem;
  padding-top: .5rem;
  border-bottom: solid 2px black;
`;

const Presentation = () => (
  <Layout data={{
    site: {
    siteMetadata: {
      title: 'Rustam Kasad',
      description: 'Craft Demo'
    }
    }
    }}>
    <Tile>
      <TopicHeader>BIO</TopicHeader>
      <p>
        Grew up in Pennsylvania, close to Philadelphia, and moved to San Diego after college. I live downtown, near Petco Park, with my girlfriend and our dog, Trooper.
      </p>

      <TopicHeader>blog</TopicHeader>
      <p>
        I occasionally write about software development 
        at <CustomAnchor url="https://codeacapella.com" text="Code Acapella" />. 
      </p>

      <TopicHeader>Clojure</TopicHeader>
      <p>
       I have been learning Clojure over the past few years and have experimented with writing generative music in a Clojure-based domain-specific language called <CustomAnchor url="https://blog.djy.io/alda-a-manifesto-and-gentle-introduction/" text="alda"/>.
      </p>

      <p>
      To help me along the this path, I've been reading more on functional programming, including the book, <CustomAnchor 
       url="https://www.amazon.com/Little-Schemer-Daniel-P-Friedman/dp/0262560992" 
       text="The Little Schemer" />. I've also been working through the problems on 4clojure.net (it's sort of like Project Euler for Clojure). You can view my profile at: <CustomAnchor url="https://www.4clojure.com/user/rustamkasad" text="https://www.4clojure.com/user/rustamkasad" />.
      </p>

       <TopicHeader>Talks and presentations</TopicHeader>
       <p>
         In Feburary of 2017, I gave a <CustomAnchor 
           url="https://www.sandiegojs.org/event/2017-02-monthly" 
           text="lightning talk on how function binding works with the `this` keyword" 
         /> in Javascript at the San Diego JS meetup. 
           You can see my <CustomAnchor url={jsBindPdf} text="presentation notes here" />.
       </p>

        <TopicHeader>HOBBY WEB DEV</TopicHeader>
        <p>
          I built a website to demonstrate the Git file staging/commit process as a state machine: <CustomAnchor url=" https://gitmachine.zubin.io/" text="(Welcome to the [git] Machine)" />. This was an exercise to edify my own understanding of Git and also to use <CustomAnchor url="https://github.com/davidkpiano/xstate" text="xstate" />, a Javascript library for defining finite state machines.
        </p>

        <TopicHeader>MUSIC THEORY</TopicHeader>
        <p>
          <span>
          I built a <CustomAnchor url="https://unostring.com" text="simple web application, called UNOstring" />. which provides a simple exercise to help guitarists practice modal improvisation. This site was inspired by Mick Goodrick's excellent book, <b>The Advancing Guitarist</b>.
          </span>
        </p>

        <TopicHeader>MAKING MUSIC</TopicHeader>
        <p>
          I have played guitar most of my adult life. About five years ago, I 
          started playing with a (now defunct) band 
          called <CustomAnchor 
            url="https://soundcloud.com/exchameleon" 
            text="Ex Chameleon" />.
          We recorded a song last fall and got it professionally mixed, which was a milestone for us! 
        </p>
        <p>
          Recently, I've been learning to play the drums.
        </p>

    </Tile>
  </Layout>
);

export default Presentation;
